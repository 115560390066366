import React from "react";
import Header from "../components/common/header";
import "../styles/home.css";
import OurServices from "../components/home/ourServices";
import Cars from "../components/home/cars";
import Footer from "../components/common/footer";
import WhyChoose from "../components/home/whychoose";
import Whatsapp from "../components/common/Whatsapp";

import HeroMar from "../components/home/hero/HeroMar.js";
import BookingFormDN from "../components/home/BookingFormDN.js";
// this is sample to check github

const Home = () => {
  return (
    <div className="main-container">
      <Whatsapp />
      <HeroMar />

      <Cars />
      <WhyChoose />
      <OurServices />
      <Footer />
    </div>
  );
};

export default Home;
