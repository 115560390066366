import React from "react";
import "./HeroMar.css";
import heroImage from "../../../assets/home/hero/expanderweb.png";
// social media icon imports
import { FaFacebook } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaYoutube } from "react-icons/fa";
import { AiFillTikTok } from "react-icons/ai";
import BookingFormDN from "../BookingFormDN";
import { useState } from "react";
import BurgerMenu from "./BurgerMenu";
import { use } from "react";

const HeroMar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [bmOpen, setBmOpen] = useState(false);

  return (
    <>
      <header className="header" id="header">
        <nav className="navbar container">
          <a href="#" className="brand">
            YULU DUBAI
          </a>

          <div className="menu" id="menu">
            <ul className="menu-inner">
              <li className="menu-item">
                <a href="#" className="menu-link">
                  Home
                </a>
              </li>
              <li className="menu-item">
                <a href="#" className="menu-link">
                  Service
                </a>
              </li>
              <li className="menu-item">
                <a href="/about" className="menu-link">
                  About
                </a>
              </li>
              <li className="menu-item">
                <a href="/contact" className="menu-link">
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <a href="#" className="menu-block">
            Contact Via WhatsApp
          </a>

          <div className="burger-container" onClick={() => setBmOpen(true)}>
            <div className="burger" id="burger">
              <span className="burger-line"> </span>
              <span className="burger-line"></span>
              <span className="burger-line"></span>
            </div>
            {bmOpen ? <BurgerMenu className="burger-menu" /> : null}
          </div>
        </nav>
      </header>

      <main className="main">
        <section className="section banner banner-section">
          <div className="container banner-column">
            <img className="banner-image" src={heroImage} alt="banner" />
            <div className="banner-inner">
              <h1 className="heading-xl">Drive Your Dream Car Today</h1>
              <p className="paragraph">
                Unleash the joy of driving with Yulu Rent a Car. From luxurious
                rides to affordable options, we provide a seamless rental
                experience tailored just for you. Discover unmatched comfort and
                style—book now and make every journey unforgettable.
              </p>
              <button
                className="btn btn-darken btn-inline"
                onClick={() => setIsOpen(true)}
              >
                Book Now<i className="bx bx-right-arrow-alt"></i>
              </button>
            </div>
            <div className="banner-links">
              <a href="https://web.facebook.com/yuludubai/" title="" className="bl-icon">
                <FaFacebook />
              </a>
              <a href="https://www.instagram.com/yulu_dubai/" title="" className="bl-icon">
                <AiFillInstagram />
              </a>
              <a href="#" title="" className="bl-icon">
                <FaYoutube />
              </a>
              <a href="#" title="" className="bl-icon">
                <AiFillTikTok />
              </a>
            </div>
          </div>
          <BookingFormDN open={isOpen} setIsOpen={setIsOpen} />
        </section>
      </main>
    </>
  );
};

export default HeroMar;
