import React, { useEffect } from "react";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputLabel, Select, MenuItem, FormControl } from "@mui/material";
import { motion } from "framer-motion";

const BookingFormDN = ({ open, setIsOpen }) => {
  const form = useRef();
  const [car, setCar] = useState("def car");

  if (!open) return null;

  // Send email
  const sendEmail = (e) => {
    e.preventDefault();
    try {
      emailjs
        .sendForm("service_s2e2l1e", "template_xlmncw9", form.current, {
          publicKey: "dz364DJ-_-jYbSWiF",
        })
        .then(
          () => {
            console.log("SUCCESS!");
            toast.success("Booking Confirmed", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = async (e) => {
    setCar(e.target.value);
  };

  return (
    <div>
      <motion.div
        initial={{ opacity: 0, scale: 0.98 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.25 }}
        className="booking-form__overlay"
      >
        <form ref={form}>
          <div className="booking__form ">
            <h4>Drive Your Vehicle Now</h4>
            <div className="booking__form__input__fields">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your Name Here"
                required
              />
            </div>
            <div className="booking__form__input__fields">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="example@example.com"
                required
              />
            </div>
            <div className="booking__form__input__fields">
              <label htmlFor="email">Phone Number</label>
              <input
                type="email"
                required
                id="email"
                name="phone_number_1"
                placeholder="Required"
              />
            </div>
            <div className="booking__form__input__fields">
              <label htmlFor="email">Phone Number</label>
              <input
                type="email"
                id="email"
                name="phone_number_2"
                placeholder="Optional"
              />
            </div>

            <div className="booking__form__input__fields">
              <label htmlFor="city">City</label>
              <select
                className="booking-form-input-fields-emirates"
                type=""
                id="city"
                name="city"
              >
                <option className value="Dubai">
                  Dubai
                </option>
                <option value="Abu Dhabi">Abu Dhabi</option>
                <option value="Sharjah">Sharjah</option>
                <option value="Ajman">Ajman</option>
                <option value="Ras AL-Khaimah">Ras AL-Khaimah</option>
                <option value="Fuhairah">Fuhairah</option>
                <option value="Umm AL-Quwain">Umm AL-Quwain</option>
              </select>
            </div>

            <div className="booking__form__input__fields">
              <label>Select Your Car Here</label>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label select-car"
                  sx={{ color: "black" }}
                >
                  Select Car
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={car}
                  label=" Select Car "
                  name="car"
                  onChange={handleChange}
                  sx={{
                    color: "black",
                    fontFamily: "Futura-std-book",

                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                      borderRadius: 0,
                    },
                    color: "black",
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                  }}
                >
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Toyota Rush Ex"}
                  >
                    Toyota Rush Ex
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Expander Highline 2024"}
                  >
                    Expander Highline 2024
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Mercedes Benz S500 2024"}
                  >
                    Mercedes Benz S500 2024
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Maserati MC20 2023"}
                  >
                    Maserati MC20 2023
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Range Rover Vogue P530 2024"}
                  >
                    Range Rover Vogue P530 2024
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Mercedes G63 Double Night Package 2024"}
                  >
                    Mercedes G63 Double Night Package 2024
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Mercedes Benz E Class AMG 2024"}
                  >
                    Mercedes Benz E Class AMG 2024
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Bentley Continental GT 2021"}
                  >
                    Bentley Continental GT 2021
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Mercedes Maybach S680 2022"}
                  >
                    Mercedes Maybach S680 2022
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Bentley Flying Spur W12 – Black Edition 2022"}
                  >
                    Bentley Flying Spur W12 – Black Edition 2022
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Rolls Royce Ghost 2022 Black Badge"}
                  >
                    Rolls Royce Ghost 2022 Black Badge
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={
                      "Mercedes AMG G63 2022 Nardo Grey Double Night Package"
                    }
                  >
                    Mercedes AMG G63 2022 Nardo Grey Double Night Package
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Lamborghini Aventador SVJ 2020"}
                  >
                    Lamborghini Aventador SVJ 2020
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Chevrolet Corvette Stingray 2023"}
                  >
                    Chevrolet Corvette Stingray 2023
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Ferrari Portofino M 2023"}
                  >
                    Ferrari Portofino M 2023
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Ford Mustang 2021"}
                  >
                    Ford Mustang 2021
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Lamborghini Huracan"}
                  >
                    Lamborghini Huracan
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Lamborghini URUS 2022"}
                  >
                    Lamborghini URUS 2022
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Maserati MC20 2023"}
                  >
                    Maserati MC20 2023
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Porsche 911 Turbo S 2021"}
                  >
                    Porsche 911 Turbo S 2021
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Porsche Carrera 4 GTS 2021"}
                  >
                    Porsche Carrera 4 GTS 2021
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Porsche GT3 2024"}
                  >
                    Porsche GT3 2024
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Futura-std-book",
                    }}
                    value={"Audi RS5 Sportback 2022"}
                  >
                    Audi RS5 Sportback 2022
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="booking__form__input__fields">
              <label htmlFor="city">Pickup Time</label>
              <input type="time" id="city" name="from_date" />
            </div>
            <div className="booking__form__input__fields">
              <label htmlFor="city">Drop Date</label>
              <input type="date" id="city" name="to_date" />

              <label htmlFor="city">Drop Time</label>
              <input type="time" id="city" name="to_date" />
            </div>
            <div className="booking__form__button__container">
              <button className="button__close" onClick={()=>{setIsOpen(false)}}>
                Close
              </button>
              <button
                type="submit"
                className="button__book"
                onClick={sendEmail}
              >
                Book Now
              </button>
            </div>
          </div>
        </form>
      </motion.div>
      <ToastContainer />
    </div>
  );
};

export default BookingFormDN;
