import React from "react";
import "./burgermenu.css";
import { useNavigate }from "react-router-dom";



const BurgerMenu = () => {
  const navigate = useNavigate();
  


  return (
    <>
      <div className="burger-menu-container">
        <div className="bm-item" onClick={() => navigate("/")}>Home</div>
        <div className="bm-item">Services</div>
        <div className="bm-item" onClick={() => navigate("/about")}>About</div>
        <div className="bm-item" onClick={() => navigate("/contact")}>Contact</div>
      </div>
    </>
  );
};

export default BurgerMenu;
